.statusCard {
    text-align: center;
    padding: 40px 0; 
  }
.statusCard h2 {
      color: #08c327; 
      font-weight: 600;
      font-size: 35px;
      margin-bottom: 10px;
    }
.statusCard p {
      color: #404F5E; 
      font-size:20px;
      margin: 0;
    }
.successIcon{
    border: 4px solid #08c327;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 20px 0 30px;
}    
.statusCard i {
    color: #08c327;
    font-size: 58px;
    margin-left: -12px;
  }
.statusCard .card {
    background: transparent;
    padding: 60px;
    border-radius: 4px; 
    display: inline-block;
    box-shadow: none;
    margin: 0 auto;
  }
  .statusCard h6{ 
    font-size: 18px !important;
    margin-bottom: 30px;
}
  .statusCard p{ 
      font-size: 14px !important;
      margin-bottom: 10px;
  }