
body { 
    background: #fff; 
}
.header-nav.navbar {
    padding: 0.60rem 1.5rem;
}
.main-layout {
    max-width: 100%;
    width: 100% !important;
    padding: 20px 30px;
}
.faqs button.accordion-button {
    max-width: 100% !important;
    border: 1px solid #e7e7e7;
    border-radius: 10px !important;
    color: #333;
    font-family: system-ui;
}
.btn-primary {
    color: #fff;
    background-color: #6a3dfd;
    border-color: #6a3dfd;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border: none;
    min-height: 25px;
    height: 45px;
    font-family: system-ui;
    font-size: 0.95rem !important;
    background: linear-gradient(90deg, #009688 35%, #2dc733 100%);
    background: linear-gradient(90deg, rgb(51 102 241) 35%, rgb(0 150 212) 100%);
    background: linear-gradient(90deg, rgb(106 61 253) 35%, rgba(141,0,255,1) 100%);
    background: linear-gradient(90deg, rgb(84 42 223) 35%, rgb(133 42 221) 100%);
    background: linear-gradient(90deg,#ff2f63 35%,#8223f7);
}
.column:last-child {
    padding: 0;
    position: relative;
}
.card{
    box-shadow: none;
} 
.tool-header button.btn svg {
    width: 20px;
    margin: 0;
    height: inherit;
    margin-right: 5px;
    fill:#222;
}
.tool-header button.btn.upscaleBtn svg{
    width: 14px;
}
.tool-header button.btn.upscaleBtn{
    display: none;
    width: 14px;
}
.tool-header h2 {
    font-size: 1.1rem;
    font-weight: 800;
    margin: 0;
    padding: 0;
    line-height: 24px;
}
.tool-header .svgIcon {
    background: #ffe7ed;
    display: flex;
}
.clockEta {
    padding: 0 10px 0;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    color: #d33f89;
}
.side-bar-nav {
    width: 280px;
    left: 0;
    top: 20px;
    z-index: 9;
    background: #fff;
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
    float: left;
    padding: 20px 20px 20px 30px;
    border-right: 1px solid #dbdbdb;
    background: #fff;
}
.side-bar-nav h2 {
    font-size: 1rem;
    font-weight: 600;
    margin: 10px 0 25px;
    padding: 0;
    line-height: 24px;
    font-family: system-ui;
    text-transform: uppercase;
}
.side-bar-nav ul, .side-bar-nav ul li{
    margin: 0;
    padding: 0;
    list-style: none;
} 
.side-bar-nav ul li a {
    display: flex;
     padding: 0px 15px; 
    /* border: 1px solid #ebebeb; */
    margin-bottom: 10px;
    border-radius: 5px;
}
.side-bar-nav ul li .linkName{
    color: #222;
}
.side-bar-nav ul li .svgIcon svg {
    width: 16px;
    fill: #ff0570;
}
.side-bar-nav ul li a.active {
    background: #ffe7ed;
}
 
.tool-header .svgIcon svg{
    width: 20px;
    fill: #ff0570;   
}
.side-bar-nav ul li .svgIcon {
    margin-right: 16px;
} 
.side-bar-nav ul li .linkName {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    font-family: system-ui;
}
.tool-header {  
    /* border-bottom: 1px solid #f1f1f1; */ 
    padding: 25px 14px;
    width: 100%;
    float: left;
    display: flex;
    height: 80px;
    margin: 0;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem;
}
.ai-board {
    display: flex;
    flex-direction: column;
    height: 91vh;
}
.tool-header .btn-secondary {
    padding: 5px 15px;
    font-size: 14px !important;
    font-family: system-ui;
    box-shadow: none;
    margin-top: 6px !important;
    background: #fff;
    border-color: #e1e1e1;
    text-transform: capitalize;
    color:#212529;
}
.tool-header .btn-secondary:hover {
    background: #fff;
    border-color: #e1e1e1;
}
.tool-panel{
    align-items: flex-start;
    display: flex;
    height: 94.5vh;
    justify-content: space-between;
    overflow-y: auto;
    width: 100%; 
    padding: 0;
    margin: 0;
} 
span.rangeValue, span.rangeSteps {
    float: right;
    background: #efefef;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    height: 30px;
    position: absolute;
    right: 14px;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
}
.inputCol{
    animation: inputAreaAnm .6s ease;
    border-left: 1px solid #dbdbdb;
    flex: 1 1;
    height: 100%;
    overflow: auto; 
    padding: 0 0.8rem !important;
}
.outputCol{
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
    transition: all .5s ease-in-out;  
}
.card.inputSection {
    padding: 0 10px;
    background: transparent;
    border: none;
    box-shadow: none; 
}
.ai-board-body.canvas { 
    background-color: transparent;
    flex: 1 1;
    height: 100%;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    position: relative;
    transition: all .1s ease-in-out;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: #efefef;
}
.ai-board .tool-header{
    background-color: #efefef;
    height: auto;
    padding: 5px 10px 10px;
}
.ai-board-body.canvas img {
    max-width: 96%;
    margin: auto;
    display: flex; 
}
button.accordion-button {
    max-width: 204px !important;
    margin: 0 auto;
    background: transparent;
    color: #4701ff;
    font-size: 15px;
}
.negativePrompt.form-switch {
    margin: 0 0 8px;
    padding: 0;
    padding-left:40px;
}
.accordion-item {
    background-color: transparent;
    border: none;
}
button.accordion-button {
    max-width: 208px;
}
.newImage {  
    transition: transform 0.5s ease;
    display: flex;
  }
  
  .newImage:hover {
    transform: scale(1.2);
    transition: transform 0.5s ease;
  } 
  
  
  
.accordion-button:focus {
    z-index: 3; 
    outline: 0;
    box-shadow: none;
    color: #4701ff;
}
button.accordion-button {
    max-width: 178px;
    margin: 0 auto;
    background: transparent;
    color: #4701ff;
}
.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: transparent;
    box-shadow: none;
}
.accordion-body {
    padding: 1rem 0.25rem;
}
.imageSelectionModal .modal-dialog {
    max-width: 1160px;
}
.imageSelectionModal .modal-content .modal-body {
    padding: 30px 15px 30px;
}
.modelSelection form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.modal-content h4{
    font-weight: 700;
}
.modelSelection form .form-check {
    display: flex;
    height: 260px;
    width: 260px;
    margin: 20px 10px 0;
    padding: 0;
    position: relative;
    /* background: url(https://imagekraft.ai/wp-content/uploads/2023/03/redshift-diffusion.png); */
    background-size: contain;
    border: 1px #d3d3d3 solid;
    border-radius: 12px;    
    transition: all .3s ease-in-out;
}
.modelSelection form .form-check:after {
    content: '';
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background: linear-gradient(360deg, rgb(2 0 36 / 79%) 0%, rgb(2 2 40 / 88%) -57%, rgb(255 255 255 / 0%) 63%);
} 
.modelSelection form .form-check:hover{
    box-shadow: 1px 2px 20px 0px #33333385;
}
.modelSelection form .form-check:hover:after{
    transition: all .3s ease-in-out;
    background: linear-gradient(360deg, rgba(8, 0, 152, 0.79) 0%, rgba(193, 14, 92, 0.88) -57%, rgb(255 255 255 / 0%) 63%);
} 
.analog{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Analog-Diffusion.png);
}
.cyberpunk{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Cyberpunk-Anime-Diffusion.png);
}
.disco{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Disco-Diffusion.png);
}
.dreamlike{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Dreamlike-Diffusion.png);
}
.portrait{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Portrait-Plus.png);
}
.redshift{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/redshift-diffusion.png);
}
.stable{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Stable-Diffusion.png);
}
.mo-di{
    background: url(https://imagekraft.ai/wp-content/uploads/2023/03/Mo-Di-Diffusion.png);
}
.modelSelection form .form-check input {
    display: none;
}
.modelSelection form .form-check label {  
    display: flex;
    width: 100%;
    text-align: center;
    align-items: flex-end;
    justify-content: center;
    font-size: 18px;
    font-family: system-ui;
    font-weight: 600;
    color: #fff;
    padding-bottom: 15px;
    position: relative;
    z-index: 10;
    font-family: system-ui;
}
.modelSelection form .form-check label:hover{
    cursor: pointer;
}
button.selModel {
    background: #fff;
    box-shadow: none !important;
    border: 1px solid #cad1d7;
    text-transform: capitalize;
}
.btn-secondary.selModel:focus {
    color: inherit;
    background-color: #f8fbff;
    border-color: #cad1d7;
    box-shadow: none;
}
[type="checkbox"]:not(:checked) + label:before { 
    border: 1px solid #cad1d7 !important; 
}
.form-control {
    background: white;
}
label.form-check-label {
    font-weight: 600;
    font-family: system-ui;
    /* color: #212529 !important; */
}


.imgloader {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    top:7px;
  }
  .imgloader::after,
  .imgloader::before {
    content: '';  
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #00a51c;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .imgloader::after {
    animation-delay: 1s;
  }
  
  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
.mobileView .catButton, .showPromptBtn{
    display: none; 

}

.img-download svg{
    margin:0 !important;
}
.img-download span{
    display: none;
}
.autoPrompt{
    text-transform: capitalize;
    color: #ff0570;
}
button.btn svg {
    font-size: 14px;
    margin: -2px 2px 0 0;
    fill: #ff0570;
}
.autoPrompt.btn:hover {
    box-shadow: none;
    transform: translateY(-1px);
    color: #4701ff;
}
.upgradeBtn {
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
}

.imgZoom {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.2rem;
    padding: 0.3rem;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    color: #333;
  }
  
  .imgZoom button {
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 30px;
    border: 1px solid #dddddd;
    color: #878787;
  }
  
  .imgZoom button:hover {
    background-color: #F5F5F5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .imgZoom p {
    margin: 0;
    width: 40px;
    text-align: center;
  }
  .imageSizeRadio .custom-radio {
    border: 1px solid #cacaca;
    padding: 5px 25px;
    border-radius: 4px;
    cursor: pointer;
}

.imageSizeRadio .custom-radio label.form-check-label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    line-height: 32px;
}

.imageSizeRadio .custom-radio {
    position: relative;
    padding: 16px 20px;
    width: 100%;
    cursor: pointer;
}

.imageSizeRadio .custom-radio input {
    display: none;
}

.imageSizeRadio {
    display: flex;
    justify-content: space-evenly;
}
.imageSizeRadio .custom-radio label.form-check-label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}
.imageSizeRadio .custom-radio.active {
    background: #ffe7ed;
    color: #ff0570;
    border-color: #ffc2dc;
}
@media (max-width: 573px){ 
    .tool-header button.btn svg{
        margin:0
    }
    button.dwnBtn {
        width: 125px;
    }
    .header-nav.navbar {
        padding: 0.65rem 0.8rem!important;
    }
    a.logo {
        margin: 0;
    }
    body{
        overflow: auto !important;
    }
    .mobileView .catButton{
        display: block; 
    }
    .tool-panel {
        align-items: flex-start;
        display: flex;
        height: auto;
        justify-content: space-between;
        overflow-y: auto;
        width: 100%;
        padding: 0;
        margin: 0;
        flex-direction: column-reverse;
    }
    .inputCol { 
        display: flex;
        flex-direction: column;
        border: none;
        height: auto;
    overflow: initial;
    }
    .ai-board-body.canvas { 
        display: flex;
        align-items: center;
        background-color: #efefef;
        height: auto;
        overflow: auto;
        margin: 0;
        padding: 30px 10px !important;
        justify-content: flex-start;
    }
    .ai-board .tool-header {
        background-color: #efefef;
        height: auto;
        padding: 5px 10px 10px;
        position: fixed;
        bottom: 0px;
        z-index: 999;
    }
    .outputCol {
        display: flex;
        flex: 1 1;
        flex-direction: column-reverse;
        height: auto;
        transition: all .5s ease-in-out;
        justify-content: flex-end;
    }
.mobileView{
    display: flex;
    flex-direction: column;
}
.mobileView .side-bar-nav{
    display: block;
}
.selectCategory {
    padding: 22px 22px 0;
    width: 97%;
    margin: 10px auto;
    border-radius: 5px;
    font-weight: bold;
    color: #000000bf;
}
.mobileView .side-bar-nav {
    position: absolute;
    top: 74px;
    width: 100% !important;
    border: none;
    left: -500px;
    transition: all .5s ease-in-out;  
}
.mobileView .side-bar-nav.activeSide{
    left: 0;
    transition: all .5s ease-in-out;  
}
.mobileView .btn-secondary.catButton  {
    padding: 10px 15px;
    font-size: 14px !important;
    font-family: system-ui;
    box-shadow: none;
    background: #f7f7f7;
    border-color: #ededed;
    text-transform: capitalize;
    color: #464646;
    width: 88%;
    margin: 20px auto 4px;
}
.images-wrapper {
    display: flex;
    margin: 0 auto 60px;
    max-width: 90%;
    flex-wrap: wrap;
} 
.cardHide{
  display: none;  
}
.cardShow{
    display: block;  
}
.catButton svg {
    margin-right: 21px;
    position: relative;
    width: 19px;
    right: 5px;
}
button.showPromptBtn {
    float: right;
    position: absolute;
    right: 27px;
    background: #f5f2f2;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 0;
    color: #ff465a;
}
.catButton svg {
    position: relative;
    width: 19px;
    right: 5px;
    fill: #ff465a !important;
}
.inputCol .tool-header > div {
    display: flex; 
}
.main-layout {
    max-width: 100%;
    width: 100% !important;
    padding: 5px 10px;
}
.main-layout.profile.page-layout {
    padding: 0px;
}
.profile .card .card-body {
    flex: 1 1 auto;
    padding: 0.3rem 2rem;
}
}
.upBtn{
    max-width: 150px;
    margin: 0 auto;
}
