.pricing-box h3 { 
    font-size: 20px!important; 
    font-weight: 700;
    color: #4701ff;
    margin-bottom: 20px;
}
.header-txt p {
    font-size: 1rem !important;
    font-weight: 400 !important;
}
.card.pricing-box {
    padding: 20px 30px 10px;
}
.pricing-box ul {
    padding: 0;
}
.pricing-box ul li{
    list-style: none;
    display: flex;
    font-size: 16px;
    padding: 5px;
}
.pricing-box i.tickMark{
    color: #2fca2f;
    font-size: 19px;
    font-weight: 600;
    margin: 0 18px 0 0;
}
.page-layout .accordion-header button {
    font-size: 15px;
    font-weight: 600;
}
.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); 
}
.buttonWrap{
    margin: 30px 0 20px;
}
.pricingTabs.nav-tabs {
    border-bottom: 0.0625rem solid #dee2e6;
    border: none;
    margin: 0 auto 30px !important;
    width: auto;
    background: white;
    display: table;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
}
.pricingTabs.nav-tabs .nav-link { 
    padding: 6px 20px;
}
.pricingTabs.nav-tabs .nav-item {
    margin-bottom: -0.0625rem;
    display: inline-block;
    border: none;
    border-radius: 10px;
    font-size: 15px;
}
.pricingTabs.nav-tabs .nav-link.active, .pricingTabs.nav-tabs .nav-item.show .nav-link {  
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%); 
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
    color: #fff;
    border: none;
    border-radius: 20px;
}
.loaderInline {
    height: 54px;
    text-align: center;
    margin: -28px auto 0;
}
.loaderInline .loader {
    margin: 0 auto !important;
    transform: scale(0.7);
}
 .pricing-box h5.card-subtitle {
    font-size: 45px !important;
    font-weight: 700 !important;
}
.pricing-box h5.card-subtitle span {
    font-size: 17px;
    color: #8898aa;
    font-weight: 500;
} 
.pricing-box .btn-primary-outline {
    border: 2px solid #4701ff;
    color: black;
}
.pricing-box .btn-primary-outline:hover {
    color:#fff;
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
}
.faqs .accordion-flush .accordion-item {
    background: transparent;
    border: none !important;
}
.card-subtitle-custom{
    font-size: 40px;
    font-weight: 500 !important;
    color: #525f7f;
    margin-bottom: -6px !important;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.9;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius:5px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background-color: #6a3dfd;
    border-color: #6a3dfd;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
    border-radius:50%;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background-color: #6a3dfd;
    border-color: #6a3dfd;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
    border-radius:50%;
  }
  .rangeScale {
    display: flex;
    height: 15px;
    position: absolute;
    left: 10px;
    right: 10px;
    z-index: 0;
    border: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    top: 6px;
}

.rangeWrap {
    position: relative;
    margin-top: 20px;
}

.rangeWrap input {
    z-index: 9;
    position: relative;
}

.rangeScale span {
    width: 2px;
    background: #dcdcdc;
}
.offerTxt {
    position: absolute;
    right: 50px;
    top: 50px;
    float: right;
    background: #ffd400;
    padding: 3px 10px;
    border-radius: 35px;
    font-size: 12px;
    color: #333;
    font-weight: bold;
}
.header-txt.sub-head p {
    max-width: 554px;
    text-align: center;
    margin: 0 auto;
    line-height: 25px;
    font-size: 16px !important;
}
.activebox{
    border: #6a3dfd85 solid 1px;
}
.pricing-box .btn-primary{
    background: linear-gradient(90deg, rgb(84 42 223) 35%, rgb(133 42 221) 100%);
}
.card.pricing-box.enterprise {
    padding: 20px 30px 10px;
    height: 100%;
    background: #e9f1ff;
}
.profile .card {
    height: auto;
    background: #fafafa;
}