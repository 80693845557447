.header-nav.navbar{
    padding: 0.4rem 3rem;
    color: #6a3dfd;
    font-weight: 500;
    font-size: 0.875rem;
    /* box-shadow: 0 1px 0px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);   */
    background-color: #f1f5fb;  
    transition: all 0.15s ease;  
    /* border-bottom: solid #d4d4d4 1px; */
    background-color: transparent;
    border-bottom: 1px solid #dbdbdb;
} 
.logo{ 
    background-position: top left;
    background-repeat: no-repeat;
    float: left;
    background-size: contain;
    margin: 0px 20px 0 10px;
    max-width: 140px;
}
.nav-link{
    color: #333;
    font-weight: 500;
}
.nav-link.active{
    color: rgb(240 168 14); 
}
.navbar-light .navbar-nav .nav-link {
    color: #333;
    font-weight: 600;
    padding: 7px 15px;
}
.navbar-light .navbar-nav .nav-link svg{
    width: 20px;
    height: 20px;
    color:#6a3dfd; 
    margin-right: 10px;
}
a.clrIcon.nav-link.disabled svg {
    fill: #bbbbbb;
}
.navbar-light .navbar-nav .nav-link .profile-img svg{
    fill: white;
    margin: 0 auto;
    width: 16px;
}
.dropdown-toggle::after {
    display: none;
  }
.text-decoration-none{
  text-decoration: none!important;
}
/* Font Awesome Icons have variable width. Added fixed width to fix that.*/
.icon-width { width: 2rem;}
.navbar .dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 14px;
}
.navbar .navbar-nav .dropdown-menu:before{
    left:inherit;
    right:35px;
}

.navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    justify-content: center !important;
}
.navbar-expand-lg .navbar-nav:last-child{
    float: right;
    position: absolute;
    right: 1rem;
}
.header-nav .center-nav.navbar-nav {
    margin-left: -200px;
}
.header-nav .navbar-light .navbar-nav .nav-link.active {
    color: rgb(94 114 228);
}
.center-nav svg {
    /* fill: #6a3dfd; */
    font-size: 20px;
}
svg.clrIcon {
    fill: none !important;
    font-size: 20px;
}
.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    border-color: #e5e5f8;
    background: #5c2adf14;
    color: #4701ff;
    font-weight: 600;
}
.dropdown-item:hover, .dropdown-item:focus {
    color: #4701ff;
    text-decoration: none;
    background-color: #f2eefc;
}
.header-nav .dropdown-menu .dropdown-item {
    display: flex;
    line-height: 24px;
}
.header-nav .dropdown-menu .dropdown-item > svg {
    width: 16px;
    margin: 0 14px 0 0;
    padding: 0;
    fill: #747474;
    stroke: #747474;
}
.profile-img{
    position: relative;
    width: 36px;
    height: 36px;
    top: 8px; 
    margin-right: -8px;
}
.profile-img img{
    border-radius: 50px; 
}
.templateList .dropdown-item {
    padding: 10px 30px !important;
    font-size: 15px !important;
}
.dropdown-item.active, .dropdown-item:active{
   font-weight: inherit;
} 
.navbar-expand-lg .navbar-nav .dropdown-menu { 
    width: 200px;
}
.navbar .dropdown-menu .dropdown-item {
    padding: 10px 24px;
    font-size: 14px;
}
.dropdown-menu.show.dropdown-menu-end, .basic-nav-dropdown {
    padding-bottom: 0px;
    border-radius: 0 0 10px 10px;
}
a.showAllTemplates {
    background: #4701ff !important;
    color: #fff !important;
    margin: 0;
    float: left;
    width: 100% !important;
    border-radius: 0 0 10px 10px;
    padding: 0 !important;
    text-align: center !important;
}
a.showAllTemplates:hover{
    text-indent: 5px;
}
 
.profileImgWrap {
    float: left;
    position: relative;
    left: -5px;
    margin-right: 10px !important;
    display: flex;
    align-items: center;
}
.profile-img {
    margin: 0 10px 0 0;
    top: 0;
    border-radius: 50px;
    background: #673ab7;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myprofile-link .dropdown-toggle{
    padding: 0 !important;
} 
.profileImgWrap .linkName {
    height: 37px;
    display: flex;
    align-items: center; 
}
.myprofile-link .dropdown-menu {
    top: 45px;
}
.wordsUsed {
    position: absolute;
    right: 190px;
    top: 5px;
    background: #e9e8ff;
    padding: 5px 10px;
    border-radius: 20px;
    color: #4701ff;
    font-size: 11px;
    font-family: system-ui;
    font-weight: 700;
    letter-spacing: 0.4px;
    min-width: 100px;
}
i.wIcon {
    height: 0;
    padding: 3px 4px;
    font-size: 9px;
    text-transform: uppercase; 
    border-radius: 50%;
    margin-right: 5px;
    color: #4701ff;
    font-style: normal;
}
.header-nav.navbar {
    padding: 0.79rem 3rem;
}
.header-nav .nav-link {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem; 
}
.header-nav a.nav-link.active {
    background: #edeaf7;
    border-radius: 50px;
    color: #4701ff !important;
}
 
@media only screen and (max-width: 768px) {
    .navbar-toggler:focus {
        box-shadow: none;
    }
    .dropdown-item:hover, .dropdown-item:focus, .dropdown-item.active, .dropdown-item:active { 
        background: transparent;
    }
    .navbar-light .navbar-brand {
        color: #32325d;
        z-index: 9999;
    }
    .header-nav .center-nav.navbar-nav {
        margin-left: 0;
    }
    div#basic-navbar-nav{
        height: 100vh !important; 
        border-radius: 0;
        max-width: 80%;
        padding-top: 80px;
        display: flex !important;
        flex-direction: column-reverse; 
        justify-content: flex-end !important;
    }
    .navbar-collapse { 
        position: absolute;
        left: -300px !important;
        transition: 1s;
    }
    .navbar-collapse.show {
        left: 0px !important;
        transition: 1s;
    }
    
    .navbar-expand-lg .navbar-collapse > div {
        width: 100%;
        /* background: #ffb9b9; */
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-grow: 1;
        justify-content: inherit !important;
        align-items: baseline;
        margin: 0;
        width: 99.90%;
        padding-top: 10px;
    }
    .navbar-expand-lg .navbar-nav:last-child { 
        right: 0;
        float: initial;
        position: initial;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu {
        margin: 0 0 0 10px !important;
        padding: 0;
    }
    .navbar-nav > a, .navbar-nav > div {
        margin-bottom: 10px;
    }
    .navbar .dropdown-menu .dropdown-item {
        padding: 10px 0px 10px 24px !important;
        font-size: 14px;
    }
    .templateList .dropdown-item {
        padding: 10px 0px 10px 24px !important;
    }
    a.showAllTemplates{
        border-radius: 10px;
    }
    .profileImgWrap{
        float: left;
        position: relative;
        left: -5px;
        margin-right: 10px !important;
        display: flex;
        align-items: center;
    }
    .linkName{
        float: left;
    }
    .profile-img {
        display: inline-block;
        margin: 0 10px 0 0;
        top: 0;
        border-radius: 50px;
        background: #673ab7;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile-img svg {
        fill: #fff;
    }
    button.navbar-toggler {
        border: none;
        background: transparent;
    }
    .nav-link svg {
        margin-right: 10px !important;
    }
  }
  @media only screen and (max-width: 768px){
    .wordsUsed { 
        right: 10px;
        top: 45px; 
    }
  } 
