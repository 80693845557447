
.no-results{
    font-size: 22px;
    font-style: italic;
    color: #9e9e9e;
}


.images-wrapper{
    column-count: 5;
    column-gap: 10px;
    margin: 0 5%;
}
.images-wrapper img {
    max-width: 100%;
    display: block;
  }
  
  .images-wrapper figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }
  
  .images-wrapper figure > img {
    grid-row: 1 / -1;
    grid-column: 1;
  }
  
  .images-wrapper figure a {
    color: black;
    text-decoration: none;
  }
  
  .images-wrapper figcaption {
    grid-row: 2;
    grid-column: 1;
    background-color: rgba(255,255,255,.5);
    padding: .2em .5em;
    justify-self: start;
  }
  .image-item button svg {
    font-size: 10px;
    width: 20px;
    margin: 0;
    padding: 0;
    height: auto;
}

.image-item button {
    padding: 4px;
    height: auto;
    background: transparent;
    border: none;
    box-shadow: none;
}

.image-item button:hover { 
    background: transparent;
    border: none;
    box-shadow: none;
}