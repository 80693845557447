body{
    /* background-color: #f1f5fb;*/
    /* background: #edf1fb; */
    background: #f9f9f9;
}
.modal-content { 
    border-radius: 15px;
}
.card {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
    border: none;
}
.ai-board {
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 140px); */
}
.ai-board-header{
    border-top: 1px solid rgb(226, 232, 240);
    border-left: 1px solid rgb(226, 232, 240);
    border-right: 1px solid rgb(226, 232, 240);
    border-radius: 6px 6px 0 0; 
    margin-bottom: 0.25rem;
    font-size: 1rem;
    padding: 14px 20px;
    background: #edf2f7;
}
.ai-board-header {
    text-transform: capitalize;
    border: none;
    margin: 0;
}
.ai-board-body{
    border-radius: 0 0 6px 6px;
    background: #fff;
    padding-top: 0;
    min-height: calc(100vh - 242px);
    overflow: hidden;
    height: calc(100vh - 242px);
    overflow-y: auto;
}
.ai-board-header { 
    background: #ffffff; 
    padding:22px;
}
.ai-board-header.card-title.h5 {
    margin-bottom: 0;
    font-size: 1.1rem;
    padding: 20px 24px;
}
.form-control { 
    background: #f8fbff;
}
/* .ai-content{ 
        position: relative;
        width: 100%; 
        padding: 30px;
        margin-bottom: 20px;
        transition: all 0.3s ease-in;
        text-align: left; 
        background: #f2f7ff !important;
    border: 2px solid #e1e8f3;
    box-shadow: none !important;
} */
.form-label { 
    font-weight: 600;
}
/* .ai-content { 
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    padding: 30px 40px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in;
    text-align: left; 
} */
.ai-content {
    background: #fff;
    border-radius: 8px; 
    /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%); */
    padding: 25px 25px 10px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in;
    text-align: left;
    background: #ffffff;
    border: 2px solid #e2e8ef;
    position: relative;
}
.ai-content:hover {
    background: #f7fafd;
}
.ai-content span.index {
    position: absolute;
    top: 18px;
    left: -18px;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    background: #e2e8ef;
    text-align: center;
    padding-top: 5px;
    font-size: 12px;
    border: 4px solid #fff;
}
.ai-content p {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}
.header-txt{
    padding-top: 40px;
    padding-bottom: 20px; 
}
.btn-group-wrap{ 
    transition: all 0.15s ease;
    opacity: 1;
    border-top: 1px solid #e2e8ef;
    margin-top: 20px;
    padding-top: 10px;
}
.btn-group-wrap button.btn {
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    min-width: 80px;
    text-align: left; 
}  
button.btn svg { 
    font-size: 14px;
    margin: -2px 2px 0 0;    
    fill: #6a3dfd;
}
.ai-board-header, .ai-board-header h5{
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 0.2px;
}
.ai-board .btn-primary {
    margin: 0 auto;
    display: flex;
}
.loginForm .btn-primary {
    background: linear-gradient(90deg,#4701ff 35%,#9723f7);
}
.loginForm .btn-primary:hover {
    color: #f0f0f0;
}
.noResults h5 {
    color: #938c8c;
    font-size: 22px;
}
.noResults p {
    text-align: center;
    margin: 0;
    font-size: 15px !important;
    color: #afafaf;
}
.noResults {
    text-align: center;
    margin: 22% 0;
}
.nav-item {
    margin-bottom: 0;
}
.nav-pills .nav-link {
    padding: 0.4rem 1rem;
    font-weight: 500;
    font-size: 0.85rem;
    color: #555555;
    box-shadow: none;
    /* background-color: #f1f5fb; */
    transition: all 0.15s ease;
    border: none;
    font-family: system-ui;
    border-bottom: 1px solid #dee2e7;
    border-radius: 0;
}
.nav-pills .nav-link:hover {
    color: #4701ff;
    background: #5c2adf14;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
    border-bottom: 1px solid #6a3dfd;
    border-color: #e5e5f8;
    background: #5c2adf14;
    color: #4701ff;
    font-weight: 500;
}
.blog-post-projects .tabButtons a:not([href]):not([tabindex]):hover{
    color: #4701ff !important; 
}
span.sectionUpdate { 
    position: relative;
    left: 50px;
    top: -8px;    
    float: left;
}
span.sectionUpdate .loader {
    margin: 0;
    font-size: 5px;
    position: absolute; 
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #424242;
    background-color: transparent;
}
.ai-content:active, .ai-content:focus, .ai-content:focus-within {
    border-color: #6a3dfd;
}
.card-header {
    padding: 20px 24px 0;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: none;
}
.card-header h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.form-label {
    font-weight: 600;
    font-family: system-ui;
    color: #212529;
}
.card-header p {
    line-height: 18px;
    margin-top: 5px;
    color: #a5a5a5;
    margin-bottom: -5px;
}
.nav-pills .nav-link {
    padding: 0.4rem 10px;
}

.tool-header {
    margin-top: 20px;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    float: left;
    display:flex;
} 
.tool-header > div{    
flex-grow: 1;
}
.tool-header .svgIcon svg {
    float: initial;
    width: 72%;
    margin: 2px auto 0;
    display: block;
}
.tool-header .svgIcon.icoo svg {
    width: 92%;
    margin: 0 auto 0;
}
.tool-header .svgIcon.icooPro svg { 
    margin: 7px auto 0;
    font-size: 28px;
    width: 100%;
}
.tool-header .svgIcon {
    /* background: #e0e5f3; */
    height: 44px;
    width: 44px;
    float: left;
    border-radius: 30px;
    margin-right: 12px;    
    /* border: 1px solid #e5e5e5; */
}
.tool-header span svg{
    width: 22px; 
    height: 22px;
    margin:0;
}
.tool-header h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    line-height: 24px; 
}
.tool-header p {
    margin: 0;
    font-size: 14px !important;
    color: #939dad;
}

.editMode.card {
    padding: 1rem !important;   
    border: 3px solid #fff;
}
.editMode .ai-board-header{
    background:transparent;
}
.editMode .ai-board-header.card-title.h5 {
    padding-top: 10px;
}
.editMode .ai-board-body{
    background:transparent;
}
 

/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  
  .btn-primary {
    color: #fff;
    background-color: #6a3dfd;
    border-color: #6a3dfd;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border: none;
    min-height: 25px;
    height: 45px; 
    font-family: system-ui;
    font-size: 0.95rem !important;
    background: linear-gradient(90deg, #009688 35%, #2dc733 100%);
    background: linear-gradient(90deg, rgb(51 102 241) 35%, rgb(0 150 212) 100%);
    background: linear-gradient(90deg, rgb(106 61 253) 35%, rgba(141,0,255,1) 100%);
    background: linear-gradient(90deg, rgb(84 42 223) 35%, rgb(133 42 221) 100%);
    background:linear-gradient(90deg,#4701ff 35%,#8223f7);
}
.form-control:focus {
    color: #212529; 
    border-color: #cdcdcd !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(120 0 255 / 10%) !important;
}
::-moz-selection {
    color: #fff;
    background: #6a3dfd;
  }
  ::selection {
    color: #fff;
    background: #6a3dfd;
  }
  button.btn svg {
    font-size: 14px;
    margin: -2px 2px 0 0;
    fill: #822add;
}

.card-sort-btns .btn {
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 36px;
    padding: 6px 15px;
    color: #333;
    font-size: 0.80rem;
    font-family: system-ui;
    margin-bottom: 10px;
}
.card-sort-btns .btn-outline-primary:not(:disabled):not(.disabled):active, 
.card-sort-btns .btn-outline-primary:not(:disabled):not(.disabled).active, 
.show > .card-sort-btns .btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #bfbff6;
    background: #5c2adf14;
    color: #4701ff;
}

.card-sort-btns span.badge.badge-light {
    margin-left: 10px;
    border-radius: 50px !important;
    width: 22px;
    background: #e6e6e6;
    color: #676666;
    font-size: 11px;
    margin-right: -5px;
    text-align: center; 
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 10px;
}
.card-sort-btns .active span.badge.badge-light {
    background: white;
}
.table-hover>tbody>tr:hover>* {
    background: #f2eefc !important;
    --bs-table-accent-bg: none;
}
.exampleData{
    font-weight: 500;
    margin: 10px auto;
    display: block;
    padding: 0;
    font-size: 12px;
}
.progress { 
    background-color: #cbcbcb;
    border-radius: 0; 
}
.progress-bar { 
    background: linear-gradient(90deg, rgb(84 42 223) 35%, rgb(133 42 221) 100%); 
}

.modal-content .modal-body {
    padding: 40px 30px;
}
.modal-content h4 {
    margin-bottom: 20px; 
    font-weight: 600;
    font-family: system-ui;
}
.modal-content p { 
    margin-bottom: 30px;
    font-size: 16px !important;
}
.modal-content .btn {
    padding: 10px 20px;
    height: auto; 
    font-size: 16px !important;
}

.form-select { 
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e') !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 16px 12px !important; 
} 

.genMoreLoad{
    min-height: 22px;
    margin-top:-50px; 
}
.jodit-status-bar .jodit-status-bar__item{
    text-transform: capitalize;
}
.jodit-status-bar .jodit-status-bar__item:last-child{
    display: none;
}
.card-title.h5 {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: bold;
}
.backToDashboard {
    background: #fff;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    color: #6a3dfd;
    left: 20px;
    padding: 8px 9px 6px 8px;
    position: absolute;
    text-align: left;
    top: 132px;
    z-index: 1;
} 
.side-bar-nav{
    width: 360px;
    left: 0;
    top: 20px;
    z-index: 9;
    background: #fff;
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
    float: left;
    padding: 25px 20px;
}  
.side-bar-nav .header-txt{
    display: none;
}
.side-bar-nav .card-sort-btns .btn {
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    padding: 4px 7px;
    color: #3e3e3e;
    font-size: 0.68rem;
    font-family: system-ui;
    margin-bottom: 10px;
}
.side-bar-nav .card-grid-row .card p {
    color: #8e8e8e;
    line-height: 16px;
    font-size: 0.72rem !important;
}
.side-bar-nav .card-grid-row .card-body {
    padding: 10px;
    width: 75%;
    padding-left: 0;
} 
.columns {
    max-width: 100% !important;
    padding: 0px !important;
}
.side-bar-nav .card-grid-row .card {
    width: 100%;
    margin: 10px;
}
.columns{
    display: flex; 
}
.columns.justify { 
    justify-content: center;
} 
.column:last-child{
    width: 80%;
    padding: 0 66px;
    position: relative; 
} 
.side-bar-nav .card-title.h5 {
    margin-bottom: 5px;
    font-size: 0.95rem;
    line-height: initial;
}


button.closeAlert {
    float: right;
    background: transparent;
    border-radius: 30px;
    margin: 0;
    font-size: 11px !important;
    box-shadow: none;
    padding: 0;
    top: -20px;
    position: relative;
    right: -10px;
}
button.closeAlert:hover, button.closeAlert:focus, button.closeAlert:active{
    background: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.navbar-toggler{   
position: relative;
right: 0;
z-index: 1500;
} 
/* .navbar-collapse{
    position: absolute;
    top: 43px;
} */
.navbar-collapse.show {
display: initial !important;
}
/* .navbar-collapse.show .navbar-nav { 
margin-bottom: 0; 
margin: 0 !important; 
position: initial !important;
float: left !important;
width: 100%;
} */

.sticky-top .editorHeader{
    margin:0 ;
    padding:0 64px;    
    /* width: calc(100% - 60px); */
}
.navbar-toggler:focus {
    box-shadow: inset 0px 0px 5px 1px #4701ff2e;
}
.backToDashboard.mobileView{
    display: none;
    color: #6a3dfd;
}
.header-txt h2 {
    font-size: 1.7rem;
    font-weight: 800;
    line-height: 2.5rem;
    margin: 0 0 30px;
    text-transform: capitalize;
    text-align: center; 
}
/* .ai-content {  
    white-space: break-spaces;
} */
/* .ai-content div[contenteditable="true"]:first-line {
    line-height: 0px;
} */
/*----------------------------
Responsive
-----------------------------*/
@media (max-width: 1024px){ 
    .column:last-child {
        width: 100%;
        padding: 0 66px;
        position: relative;
    }
}
@media (max-width: 990px){ 
    .container {
        max-width: 100% !important;
    }
    .card-grid-row .card {
        width: 30% !important;
        margin: 10px;
    }
    .column:last-child{
        width: 80%;
        padding: 0 25px 0 35px;
        position: relative;
        flex-grow: 1;
    } 
} 
@media (max-width: 768px){
    .card-body { 
        padding: 0.5em 1rem;
    }
    .dashboardNav{
        position: relative;
        z-index: 9;
    }
    .dashboardNav a.backToDashboard {
        left: 0;
        right: 0;
        bottom: 0;
        top: -13px;
        margin: 0 auto;
        max-width: 43px;
        height: 37px;
    }
    .btn-group-wrap button.btn { 
        line-height: 15px;
        min-width: 54px; 
        margin: 0;
    }
    .backToDashboard{
     display: none;   
    }
    .backToDashboard.mobileView{
        display: block;
        color: #6a3dfd;
    }
    .backToDashboard.mobileView svg {
        fill: #6a3dfd;
    }
    .header-nav.navbar {
        padding: 0.4rem 20px !important;
    }
    .container {
        max-width: 100%;
        padding: 0 30px;
    }
    .card-grid-row .card {
        width: 46% !important;
        margin: 10px;
    } 
    .profile-img { 
        display: none;
    }
.navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    justify-content: center !important;
    align-items: baseline;
    margin:0;
    width: 99.90%;
    padding-top: 10px;
}
.navbar-expand-lg .navbar-collapse .dropdown-menu {
    margin: -5px 0 0 -14px;
    padding: 0; 
}
.navbar-expand-lg .navbar-collapse > div{
    width: 38%;
}
.navbar-expand-lg .navbar-collapse.show { 
    height: 219px !important; 
    align-items: baseline;
}
.blog-section:hover .section-toolbox {
    display: block;
    position: absolute;
    background-color: #fff;
    padding: 25px;
    border-radius: 10px !important;
    border: none !important; 
    width: 150px;
    z-index: 2;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0 !important; 
    box-shadow: 1px 2px 17px 2px #3333335c;
    top: initial;
    bottom: 10% !important;
    max-height: 120px;
}
.blog-section .section-controls { 
    left: -40px !important;
}
.ai-content.flexbox {
    display: inline-block;
    padding: 15px 15px 15px 20px;
    width: 100%;
}
.selectBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0;
    border: 0;
    margin: 10px 0 0 0;
    border-top: dashed #e2e8ef 2px;
    border-radius: 0px;
}
.editor-main {
    width: 100%;
    padding-top: 0;
}
.sticky-top .editorHeader {
    margin: 0;
    padding: 0 30px !important; 
    width: 100%;
    text-align: center !important;
}
.editorHeader button, .editorHeader span { 
    padding:10px 6px !important; 
}
.editorHeader h5 { 
    display: block;
    width: 100%;
}
.editorHeader .dropdown{
 position: relative;
}
.editorHeader .dropdown .dropdown-menu.show{
 position: absolute !important;
}
}
@media (max-width: 576px){
    
.column:last-child {
    width: 80%;
    padding: 0 20px;
    position: relative;
    flex-grow: 1;
}
.container {
    max-width: 100% !important;
}   
.card-grid-row .card {
    width: 46% !important;
    margin: 10px;
}
.planStatus { 
    float: right;
    width: 80%;
}
.backToDashboard{
    border-radius: 5px;
    left: 38px;
}
.infoTooltip { 
    right: 31px !important;
    top: 176px !important;
}
}
@media (max-width: 573px){
    .container {
        max-width: 100% !important;
    }
    .card-grid-row .card {
        width: 100% !important;
        margin: 10px;
    } 
    .tool-header{
        display:inline-block;
        width:100%;
    }
    .planStatus {  
    margin-top: 10px!important;
    padding: 7px 12px; 
    max-width: 100%!important;
    }
    .header-nav.navbar { 
    padding: 0.4rem 0.8rem!important;
    }
   
    .blog-post-projects .card {
            height: auto !important;
    margin-bottom: 20px;
    min-height: 200px;
    }
    .blog-post-projects .tabButtons .card .card-body {
    height: auto !important;
    }
    .header-txt h2 {
    font-size: 1.48rem;
    font-weight: 800;
    line-height: 30px;
    margin: 0 0 10px;
    text-transform: capitalize;
    text-align: center; 
    }
    .header-txt p {
        display: block;
        padding: 0 25px;
    }
    .blog-post-wrap h2 {
        font-size: 1.38rem !important;
        margin: 0;
        font-weight: 800;
    }
    .cursor {
        width: 100%;
        float: inherit !important;
    }
    .editor-main .blog-title h1.input {
        font-size: 20px !important;
        font-weight: 800;
    }
    .card-sort-btns .btn { 
    font-size: 0.70rem; 
    margin-bottom: 10px;
    }
    .card { 
    margin-bottom: 20px;
    padding: 0 !important;
    }
    .editMode.card{
    padding: 0 !important;
    }
    .sideBar{
    padding-right: 15px !important;
    } 
    .blog-post-projects table tr th:nth-child(2),
    .blog-post-projects table tr td:nth-child(2) {
        display: none;
    }
    
    .blog-post-projects table tr th:nth-child(3),
    .blog-post-projects table tr td:nth-child(3) {
        display: none;
    }
    .blog-post-projects table tr td button {
        padding: 0 !important;
        border: none !important;
        margin-bottom: 10px;
    }
    .ai-content { 
        padding: 15px 15px 5px; 
        font-size: 14px;
    }
   .editMode .ai-section .btn-group-wrap button.btn { 
        min-width: 44px;
        margin: 0;
        padding-right: 5px;
        font-size: 11px !important;
    }
}
.ai-content span.index { 
    z-index: 6;
}
.genMoreLoad {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    z-index: 5;
    background: #ffffff8a;
    border-radius: 5px;
}
span.sectionUpdate, .project-content-loader { 
    position: absolute;
    background: #ffffffb3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    z-index: 5;
}
span.sectionUpdate .loader { 
    font-size: 8px;
    margin-top: -40px; 
}
.genMoreLoad { 
    margin-top: 0;
}
.side-bar-nav .card {
    border: none;
    border-radius: 0;
    box-shadow: none;
} 
.side-bar-nav .card-grid-row .card {
    width: 100%;
    margin: 0 0 10px;
    padding: 5px;
    border-radius: 5px;
}
.side-bar-nav .card-icon svg {
    margin: 0 auto;
    font-size: 20px !important;
    height: 20px;
}
.side-bar-nav .card-title.h5 {
    margin-bottom: 0;
    font-size: 0.85rem;
}
.side-bar-nav .card-grid-row .card p{
    display: none;
}
.side-bar-nav .card-icon {
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: initial;
}
.side-bar-nav .card-icon .svgIcon {
    border-radius: 0;
    height: 20px;
    width: 40px;
    display: inline-block;
    padding: 0;
    margin: 0 4px 0 0;
}
.side-bar-nav .card-grid-row .card-body{
    padding: 0;
}
.accordion-flush .accordion-item { 
    background: transparent;
    border: 1px solid #d1d1d1 !important;
}
.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    background: transparent;    
    font-size: 16px;
}
.btn-success {
    color: #fff;
    background-color: #4701ff; 
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    font-family: system-ui;
    font-size: 16px;
    border: none;
    text-shadow: none;
}
.btn-success:hover, .btn-check:focus+.btn-success, .btn-success:focus {
    background-color: #5800ff !important;
}
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4701ff;
    border-color: #4701ff;
}
.upgradeIcon svg {
    border: #d9caff solid 1px;
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 15px;
    box-shadow: 0 0 0 8px #eef1ff;
}
.upgradeModal .modal-dialog{
    max-width: 588px; 
} 
.breakSpaces{
    white-space: break-spaces;
}