.planStatus{  
    color: rgb(87, 87, 87);
    font-size: 12px;
    padding: 7px 12px;
    margin-top: -10px;
    position: relative;
    border-radius: 6px;
    max-width: 300px;
    height: 100%;
    display: flex;
    /* background: #e0e5f3; */
}
.planStatus > div{
    flex-grow: 1;
}
.planStatus .labelTxt{
    font-size: 11px; 
    color: #7c7676;
    margin-top: 1px;
}
.planStatus > div:last-child {
    width: 30px;
    text-align: right;
    vertical-align: middle;
    padding-top: 8px;
}
.planStatus > div:first-child {
    text-align: left;
}
.planStatus > div {
    flex-grow: 1;
}
.planStatus > span{
    text-align: right;
    display: inline-block; 
}
.planStatus strong{
    text-transform: capitalize;
}
.planStatus .progress{
    margin: 3px 0 0;    
    border-radius: 0;
    max-width: 280px;
}
.planStatus .progress-bar {
    height: 5px;
    border: 0px;
}
.infoTooltip {
    position: absolute;
    right: 77px;
    top: 66px;
    font-size: 14px !important;
    fill: #979797;
    cursor: pointer;
} 
  .infoTooltip .tooltiptext {
    visibility: hidden;
    width: 251px;
    background-color: #585858fa;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    right: 25px;
    font-size: 12px;
    font-family: monospace;
  }
  
  .infoTooltip:hover .tooltiptext {
    visibility: visible;
    cursor: pointer;
  }