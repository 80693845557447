
.no-results{
    font-size: 22px;
    font-style: italic;
    color: #9e9e9e;
}


.images-wrapper{
    column-count: 5;
    column-gap: 10px;
    margin: 0 5%;
}
.images-wrapper img {
    max-width: 100%;
    display: block;
  }
  
  .images-wrapper figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }
  
  .images-wrapper figure > img {
    grid-row: 1 / -1;
    grid-column: 1;
  }
  
  .images-wrapper figure a {
    color: black;
    text-decoration: none;
  }
  
  .images-wrapper figcaption {
    grid-row: 2;
    grid-column: 1;
    background-color: rgb(44 44 44 / 83%);
    padding: 0.2em 0.5em;
    justify-self: start;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
  .image-item button svg {
    font-size: 10px;
    width: 20px;
    margin: 0;
    padding: 0;
    height: auto;
    stroke: #fff;
}

.image-item button {
    padding: 4px;
    height: auto;
    background: transparent;
    border: none;
    box-shadow: none;
}

.image-item button:hover { 
    background: transparent;
    border: none;
    box-shadow: none;
    color:#fff;
}
.images-wrapper figcaption button {
  font-size: 11px !important;
  color: #fff;
}

.images-wrapper figcaption button svg {
  fill: #fff;
  width: 14px;
}