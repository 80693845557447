@media (min-width: 1200px){
    .container {
        max-width: 1170px;
    }
}
.card-grid-row{
    display: flex; 
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.card-grid-row .card{
    width: 31%;
    margin: 10px;
}
.card-grid-row .card:hover{
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}
.card-grid-row .card-body { 
    padding: 20px;
    width: 75%;
    padding-left: 0;
}
.card-grid-row .card p {
    color: #979797;
    line-height: 19px;
    font-size: 0.82rem !important;
}
.card-wrap{
    display: flex;
}
.card-icon{
    display: flex;
    vertical-align: middle; 
    align-items: center;
    width: 25%;
}
.card-icon i{
    margin:0 auto;
}  
.card-icon svg{
    margin: 0 auto;
    font-size: 40px;
}
.card-title.h5 {
    margin-bottom: 0.25rem;
    font-size: 1rem;
} 
.header-txt h5{
    font-size: 1.20rem;
    font-weight: 600;
    margin: 0 0 5px;
}
.header-txt p{
    font-size: 0.75rem;
    font-weight: 400;
}
.user-name{
    text-transform: capitalize;
    font-weight: 400;
}

.card[data-category="blog"] {
    border-left: solid #2196f3 4px;
}

.card[data-category="website"] {
    border-left: solid #7cb342 4px;
}

.card[data-category="socialMedia"] {
    border-left: solid #f44336 4px;
}

.card[data-category="marketing"] {
    border-left: solid #f3b915 4px;
}
.card[data-category="more"] {
    border-left: solid #11cdef 4px;
}
.card-grid-row .card{    
    border: none;
}
.card-title.h5 {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
    font-family: system-ui;
}
.secHeading h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
}
.secHeading {
    display: inline-block;
    width: 100%;
    padding: 10px;
}

.card-icon .svgIcon svg { 
    margin: 0 auto;
    display: block;
}
.card-icon .svgIcon {
    /* background: #f1f5fb; */
    margin: 10px auto;
    padding: 10px;
    border-radius: 50px;
    height: 65px;
    width: 65px;
    display: flex;
}
.card[data-category="socialMedia"] .card-icon .svgIcon svg,
.card[data-category="ads"] .card-icon .svgIcon svg,
.card[data-category="ecommerce"] .card-icon .svgIcon svg{
    width: 100%;
}
.main-layout.templates {
    margin-bottom: 60px;
} 
.card.marketing .svgIcon svg{color: #f06df5}
.card.hr .svgIcon svg{color: #ff9b44}
.card.email .svgIcon svg{color: #599cff;}
.card.career .svgIcon svg{color: #04a65a;}
.card.personal .svgIcon svg{color: #ff5858}